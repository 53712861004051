import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../components/breadcrumb'
import FragrancesList from '../components/fragrances-list'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import Rating from '../components/rating'
import SEO from '../components/seo'


export const query = graphql`
  query NoseTemplateQuery(
    $genderId: PostGraphile_Gender!,
    $imageMain: String!,
    $noseId: String!,
  ) {
    ...SiteInfo
    imageMain: file(relativePath: { eq: $imageMain }) { ...ImageMain }
    results: postgres {
      nose: noseById(id: $noseId) {
        ...Nose
        brands: brandsList(genderId: $genderId) {
          ...Brand
        }
        colleagues: colleaguesList(
          filter: { genders: { contains: [ $genderId ] }}
        ) {
          ...Nose
        }
        fragrances: fragrancesList(filter: { gender: { equalTo: $genderId }}) {
          ...Fragrance
        }
        notes: notesList(
          genderId: $genderId
          first: 5
        ) {
          ...Note
        }
      }
    }
  }
`

export default ({ data }) => {
  const { links, meta } = data.site.siteMetadata
  const { entity } = meta
  const { nose } = data.results
  const { brands, colleagues, fragrances, notes } = nose
  const imageMain = data.imageMain && data.imageMain.childImageSharp.fixed
  const gender = (nose.gender === 'MEN') ? 'Male' : 'Female';

  const page = nose.name
  const parent = 'Noses'
  const title = `${page} - ${parent}`
  const keywords = title.split(/\W+/).concat(['nose'])
  const trail = [
    {
      name: parent,
      slug: links.noses,
    },
    {
      name: nose.name,
      slug: nose.slug,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description: nose.about,
    image: imageMain && imageMain.src,
    keywords,
    name: title,
    person: nose,
    slug: nose.slug,
  }

  return (
    <Layout>
      <SEO
        description={nose.about}
        keywords={keywords}
        schema={schema}
        title={title}
      />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      {imageMain && (
        <Image
          alt={nose.name}
          credit={nose.image}
          fixed={imageMain}
        />
      )}

      {nose.about && (
        <Paragraph>
          {nose.about}
        </Paragraph>
      )}

      {nose.gender && (
        <Paragraph>
          Gender: {gender}
        </Paragraph>
      )}

      <Heading level={2}>{nose.name} {entity.plural}</Heading>
      <Rating count={nose.ratings} rating={nose.rating} />
      <br/>
      <FragrancesList fragrances={fragrances} />

      {(brands.length > 0) && (
        <>
          <Heading level={2}>{nose.name} Brands</Heading>
          <List>
            {brands.map(brand => (
              <ListItem key={brand.id}>
                <Link href={brand.slug}>
                  {brand.name}
                </Link>
              </ListItem>
            ))}
          </List>
        </>
      )}

      {(colleagues.length > 0) && (
        <>
          <Heading level={2}>{nose.name} Colleagues</Heading>
          <List>
            {colleagues.map(colleague => (
              <ListItem key={colleague.id}>
                <Link href={colleague.slug}>
                  {colleague.name}
                </Link>
              </ListItem>
            ))}
          </List>
        </>
      )}

      <Heading level={2}>
        {nose.name} Notes
      </Heading>
      <List>
        {notes.map(note => (
          <ListItem key={note.id}>
            <Link href={note.slug}>
              {note.name}
            </Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
